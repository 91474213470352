<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-container class="my-5">
            <v-row>
                <!--<v-col cols="0" md="2"></v-col>-->
                        <v-col cols="12" class="sub-title-3 mt-2 pb-0">
                            예약 리스트 (5일)
                            <div>
                            <v-btn
                                    color="primary"
                                    class="mx-2 white--text center-block float-right"
                                    small
                                    @click="btnClick(0)"
                            >
                                월별예약현황
                            </v-btn>
                            <v-btn
                                    class="mx-2 white--text center-block tmp-btn float-right"
                                    style="font-weight: bold; background-color: #E53935;"
                                    small
                                    @click="btnClick(1)"
                            >
                                실시간예약
                            </v-btn>
                            </div>
                        </v-col>
                        <v-col cols="12" class="overflow-x-auto" md="12">
                            <table class="adminListTable tableHover" style="width: 100%;">
                                <colgroup>
                                    <col style="width:4%"/>
                                    <col style="width:7%"/>
                                    <col style="width:10%"/>
                                    <col style="width:7%"/>
                                    <col style="width:7%"/>
                                    <col style="width:7%"/>
                                    <col style="width:8%"/>
                                    <col style="width:7%"/>
                                    <col style="width:7%"/>
                                </colgroup>
                                <tr>
                                    <th>번호</th>
                                    <th>신청일</th>
                                    <th>객실명</th>
                                    <th>이용요금</th>
                                    <th>예약일</th>
                                    <th>기간</th>
                                    <th>예약자</th>
                                    <th>입금자</th>
                                    <th>예약상태</th>
                                </tr>
                                <template v-if="resvList.length > 0">
                                    <tbody>
                                    <tr
                                            v-for="(item,i) in resvList"
                                            :key="i"
                                            @click="clickRow(item)"
                                            style="cursor: pointer;">
                                        <td>
                                            {{i+1}}
                                        </td>
                                        <td>{{item.lstModDate}}</td>
                                        <td>{{item.resvRoomNm}}</td>
                                        <td style="text-align: right;">{{item.resvPrice | comma}}원</td>
                                        <td>{{item.resvDate}}</td>
                                        <td>{{item.resvDateRange}}</td>
                                        <td>{{item.resvName}}</td>
                                        <td>{{item.payName}}</td>
                                        <td>
                                            <template v-if="item.resvGubun === '1'">
                                                <span style="color:blue;">{{item.resvGubunDesc}}</span>
                                            </template>
                                            <template v-else-if="item.resvGubun === '2'">
                                                <span style="color:black;">{{item.resvGubunDesc}}</span>
                                            </template>
                                            <template v-else-if="item.resvGubun === '5'">
                                                <span style="color:green;">{{item.resvGubunDesc}}</span>
                                            </template>
                                            <template v-else>
                                                <span style="color:red;">{{item.resvGubunDesc}}</span>
                                            </template>
                                        </td>
                                    </tr>
                                    </tbody>
                                    <tr class="text--blue">
                                        <td>
                                            합계
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td style="text-align: right;">{{totalPrice | comma}}원</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td colspan="8" class="py-3">
                                            예약자가 없습니다.
                                        </td>
                                    </tr>
                                </template>
                            </table>
                        </v-col>

                    </v-row>
                    <v-row>
                        <v-col cols="12" class="sub-title-3 mt-2 pb-0">
                            접속자수 및 예약현황
                        </v-col>
                        <v-col cols="12" class="overflow-x-auto" md="12">
                            <table class="adminListTable " style="width: 100%;">
                                <colgroup>
                                    <col style="width:5%"/>
                                    <col style="width:12%"/>
                                    <col style="width:10%"/>
                                    <col style="width:12%"/>

                                    <col style="width:12%"/>

                                    <col style="width:15%"/>
                                    <col style="width:12%"/>
                                    <col style="width:12%"/>
                                    <col style="width:12%"/>
                                </colgroup>
                                <tr>
                                    <th>번호</th>
                                    <th>일자</th>
                                    <th>접속자수</th>
                                    <th>예약대기</th>
                                    <th>입금완료</th>
                                    <th>실입금액</th>
                                    <th>대기취소</th>
                                    <th>환불신청</th>
                                    <th>환불완료</th>
                                </tr>
                                <tr
                                        v-for="(item,i) in statList"
                                        :key="i">
                                    <td>
                                        {{i}}
                                    </td>
                                    <td>{{item.lstModDate}}</td>
                                    <td>{{item.accCont}}명</td>
                                    <td>{{item.resvCont1}}건</td>
                                    <td>{{item.resvCont2}}건</td>

                                    <td style="text-align: right;">{{item.resvPrice2 | de0}}원</td>

                                    <td>{{item.resvCont5}}건</td>
                                   <td>{{item.resvCont3}}건</td>
                                    <td>{{item.resvCont4}}건</td>
                                </tr>
                                <tr class="text--blue">
                                    <td colspan="2">10일 합계</td>
                                    <td>{{sumWeek.accCont}}명</td>
                                    <td>{{sumWeek.resvCont1}}건</td>
                                    <td>{{sumWeek.resvCont2}}건</td>
                                    <td style="text-align: right;">{{sumWeek.resvPrice2 | de0}}원</td>
                                    <td>{{sumWeek.resvCont5}}건</td>
                                    <td>{{sumWeek.resvCont3}}건</td>
                                    <td>{{sumWeek.resvCont4}}건</td>
                                </tr>
                                <tr class="text--blue">
                                    <td colspan="2">올해 합계</td>
                                    <td>{{sumAll.accCont}}명</td>
                                    <td>{{sumAll.resvCont1}}건</td>
                                    <td>{{sumAll.resvCont2}}건</td>
                                    <td style="text-align: right;">{{sumAll.resvPrice2 | de0}}원</td>
                                    <td>{{sumAll.resvCont5}}건</td>
                                    <td>{{sumAll.resvCont3}}건</td>
                                    <td>{{sumAll.resvCont4}}건</td>
                                </tr>
                            </table>
                        </v-col>
                    </v-row>

        </v-container>

    </div>
</template>

<script>


    export default {
        name: 'AdminMainPage',
        components: {},
        data: () => ({
            dateString: "",
            date: {
                year: "",
                month: "",
                day: ""
            },
            ydateString: "",
            ydate: {
                year: "",
                month: "",
                day: ""
            },
            resvList: [],
            statList: [],
            sumWeek: {resvCont1: 0, resvCont2: 0, resvCont3: 0, resvCont4: 0, resvCont5: 0},
            sumAll: {resvCont1: 0, resvCont2: 0, resvCont3: 0, resvCont4: 0, resvCont5: 0},
            page: {
                limit: 3,  // 한페이지 표시 갯수
                offset: 0,  // %부터 보여주기
                total: '0', // 전체 갯수
                page: 1,    // 현제 페이지
                length: 1   // 페이지 표시 갯수
            },
            totalPrice:0,
        }),
        created: function () {
            // 맨위로
            window.scrollTo(0, 0);
            //this.$isAdminCheck(this.$store.state.admin.isLogin);
            //this.$isAuthCheck(this.$getCookie("AUTH"));

            // 현재 날짜 계산
            let today = new Date();

            this.date.year = today.getFullYear();
            this.date.month = ('0' + (today.getMonth() + 1)).slice(-2);
            this.date.day = ('0' + today.getDate()).slice(-2);
            this.dateString = this.date.year + '-' + this.date.month + '-' + this.date.day;

            let ydate = new Date(new Date().setDate(this.date.day - 4));

            this.ydate.year = ydate.getFullYear();
            this.ydate.month = ('0' + (ydate.getMonth() + 1)).slice(-2);
            this.ydate.day = ('0' + ydate.getDate()).slice(-2);

            this.ydateString = this.ydate.year + '-' + this.ydate.month + '-' + this.ydate.day;

            this.getResv();
            this.getStatWeek();

        },
        methods: {
            getResv() {

                let fdata = {
                    "startDate": this.ydateString,
                };

                return this.$store.dispatch("admin/getResvList", fdata)
                    .then((resp) => {
                        setTimeout(() => {

                            this.resvList = resp.message;

                            this.page.total = resp.total;
                            this.setPage();
                            if(resp.total > 0){
                                this.setTotPrice();
                            }
                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            setTotPrice(){
                for(let i=0 ; this.resvList.length > i ; i++){
                    if(this.resvList[i].resvGubun == "1" || this.resvList[i].resvGubun == "2")
                    this.totalPrice += Number(this.resvList[i].resvPrice);
                }
            },
            getStatWeek() {

                let fdata = {
                    roomId: this.roomId
                };

                return this.$store.dispatch("admin/getStatWeek", fdata)
                    .then((resp) => {
                        setTimeout(() => {

                            this.statList = resp.message;
                            this.sumWeek = resp.sumWeek;
                            this.sumAll = resp.sumAll;
                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            setPage() {

                this.page.length = Math.ceil(this.page.total / this.page.limit);
                if (this.page.length === 0) this.page.length = 1;

            },
            next(page) {
                this.page.page = page;
                this.page.offset = this.page.limit * (this.page.page - 1);
                //this.getList();
                this.getResv();
            },
            clickRow(row){

                let options = 'top=10, left=10, width=700, height=800, status=no, menubar=no, toolbar=no, resizable=no';
                let route = '/popup/resv/info?resvId=' + row.resvId;
                let popNm = "상세정보" + row.resvId;
                window.open(route, popNm, options);

            },
            btnClick(val){
                if(val === 0){
                    location.href = "/admin/resv/calendar";

                }else if(val === 1){
                    let routeData = this.$router.resolve({path: '/reservation/search'});
                    window.open(routeData.href, '_blank');
                }

            },
            newResvClick() {

            },

        },
        mounted() {
            this.$nextTick(() => {

            })
        },
        computed: {
            //resvConfirmInfo() { return this.$store.state.reservation.resvConfirmInfo }
        },
    }
</script>

<style>

</style>

